import "./App.css";
import React, { useEffect, useState } from "react";

const texts = [
  "Customized Admin & HR solutions will soon be a click away.",
  "Cutting edge tech solutions will soon help you focus on implementing HR policies, while we take care of the mundane routines...",
  "Statutory process, visa applications, renewals, payroll all at the click of a button..",
];

const slideDurations = [6500, 12000, 9000];

function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeProp, setFadeProp] = useState("fade-out");

  useEffect(() => {
    const initialFadeInTimeout = setTimeout(() => {
      setFadeProp("fade-in");
    }, 100); // Initial delay of 100ms for fade-in effect

    const fadeOutTimeout = setTimeout(() => {
      setFadeProp("fade-out");
    }, slideDurations[currentIndex] - 1000); // Start fading out before next slide

    const slideTimeout = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
      setFadeProp("fade-in");
    }, slideDurations[currentIndex]);

    return () => {
      clearTimeout(initialFadeInTimeout);
      clearTimeout(fadeOutTimeout);
      clearTimeout(slideTimeout);
    };
  }, [currentIndex]);

  return (
    <div className="carousel-container">
      <img
        src={process.env.PUBLIC_URL + "/images/TechnofinLogo.png"}
        alt="Technofin Logo"
        className="logo-image"
      />
      <div className="text-wrapper">
        <div
          className={`carousel-text ${fadeProp}`}
          dangerouslySetInnerHTML={{ __html: texts[currentIndex] }}
        />
      </div>
      <div className="contact-text">
        To know more, <br />
        Write to <span className="itallics">"</span>
        <a href="mailto:askus@technofin.io" className="email-link">
          askus@technofin.io
        </a>
        <span className="itallics">"</span>
      </div>
    </div>
  );
}

export default App;
